import React, { useState, useEffect, useContext } from "react";
import Nav from "../components/nav";

import Sidebar from "../components/sideBar";
import Details from "../components/details";
import { AuthContext } from "../context/authContext";
import AllList from "../list/AllList";
function Listing() {
  const { currentUser } = useContext(AuthContext);

  /* Search input
  const [inputs, setInputs] = useState([]);
  //

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = posts.slice(firstIndex, lastIndex);
  const npage = Math.ceil(posts.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  console.log(records);
  useEffect(() => {
    const fetchPosts = async () => {
      //  const res = await axios.get("/posts");
      const res = await http.get(`property/${firm}`);
      setInputs(res.data);
      setPosts(res.data);
    };
    fetchPosts();
  }, []);

  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.body.toLowerCase().includes(e.target.value))
    );
  };

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changePage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
*/
  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
            <Details
              th1={"Total Venues"}
              th2={"Total Cities"}
              th3={"Total States"}
              th4={"Vacant Properties"}
              // total={posts.length}
            />

            {/* The table */}

            {/* The search 
            <form class="templatemo-search-form" role="search">
              <button type="submit" class="fa fa-search"></button>
              <input
                type="text"
                class="form-control"
                placeholder="Search Market here"
                name="srch-term"
                id="srch-term"
                onChange={changeHandler}
              />
            </form>*/}
            <div class="templatemo-content-widget no-padding">
              <div class="panel panel-default table-responsive">
                <AllList />{" "}
                {/* Pagination 
                <div className="col-lg-12 col-12">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center mb-0">
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Previous"
                          onClick={prePage}
                        >
                          <span aria-hidden="true">Prev</span>
                        </Link>
                      </li>
                      {numbers.map((n, i) => {
                        <li
                          className="page-item active"
                          aria-current="page"
                          key={i}
                        >
                          console.log(n)
                          <Link
                            className="page-link"
                            to="/market"
                            onClick={changePage}
                          >
                            <span aria-hidden="true">{n}</span>
                          </Link>
                        </li>;
                      })}

                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Next"
                          onClick={nextPage}
                        >
                          <span aria-hidden="true">Next</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Listing;
