import React from "react";
import { Link } from "react-router-dom";
import http, { orderEndpoint } from "../components/http";
import toast from "react-hot-toast";
import moment from "moment";

function OrderTable(props) {
  let order = props.list;
  // let order = props.list;
  let num = 1;
  const handleDelete = async ({ id }) => {
    try {
      await http.delete(orderEndpoint + id);

      toast.success("Order Deleted Successfully");
    } catch (error) {
      toast.error(error.message, {
        duration: 60,
      });
    }
  };
  return (
    <div class="templatemo-content-widget no-padding">
      <div class="panel panel-default table-responsive">
        <table class="table table-striped table-bordered templatemo-user-table">
          <thead>
            <tr>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  # <span class="caret"></span>
                </a>
              </td>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  Customer <span class="caret"></span>
                </a>
              </td>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  Order <span class="caret"></span>
                </a>
              </td>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  Description <span class="caret"></span>
                </a>
              </td>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  Collection Date <span class="caret"></span>
                </a>
              </td>
              <td> Paid Date</td>
              <td>Edit</td>
              <td>View</td>

              <td>Delete</td>
            </tr>
          </thead>
          {order &&
            order.map((tenant, key) => {
              return (
                <tbody>
                  <tr key={key}>
                    <td>{num++}</td>
                    <td>{tenant.customer}</td>
                    <td>{tenant.order}</td>
                    <td>{tenant.description}</td>
                    <td>{moment(tenant.collectDate).fromNow()}</td>
                    <td>{moment(tenant.PaidDate).fromNow()}</td>
                    <td>
                      <Link
                        to={`/addTenant/${tenant.property}`}
                        state={tenant}
                        class="templatemo-edit-btn"
                      >
                        {" "}
                        Edit
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/property/${tenant.property}`}
                        state={tenant}
                        className="templatemo-link"
                      >
                        View
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link
                        to={`/addRent/${tenant.name}/${tenant.property}/`}
                        className="templatemo-link"
                      >
                        Add Payment
                      </Link>
                    </td>
                    <td>
                      <Link
                        to=""
                        onClick={() => handleDelete(tenant)}
                        class="templatemo-edit-btn"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </tbody>
              );
            })}
        </table>
      </div>
    </div>
  );
}

export default OrderTable;
