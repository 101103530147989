import { useState } from "react";
import React from "react";

function Multifield(props) {
  const [hallData, setHallData] = useState([
    {
      //  hallName: "",
      order: "",
      price: "",
    },
  ]);

  const handleClick = () => {
    setHallData([
      ...hallData,
      {
        order: "",
        price: "",
      },
    ]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onChangeVal = [...hallData];
    onChangeVal[i][name] = value;
    setHallData(onChangeVal);
    let price = JSON.stringify(hallData);
    props.setField(price);
  };

  const handleDelete = (i) => {
    const deleteVal = [...hallData];
    deleteVal.splice(i, 1);
    setHallData(deleteVal);
  };
  return (
    <div>
      <div class="col-lg-12 align-self-center">
        <div class="row">
          {hallData.map((val, i) => (
            <>
              <div class="form-group">
                <input
                  type="text"
                  name="order"
                  value={val.order}
                  placeholder="Number of Order"
                  autocomplete="on"
                  required
                  onChange={(e) => handleChange(e, i)}
                />
              </div>

              <div class="form-group">
                <input
                  type="number"
                  name="price"
                  value={val.price}
                  placeholder="Price"
                  autocomplete="on"
                  required
                  onChange={(e) => handleChange(e, i)}
                />
              </div>
            </>
          ))}
        </div>
      </div>
      <br />
      <button onClick={handleClick}>Add More</button>
      <button onClick={(i) => handleDelete(i)}>Delete</button>
    </div>
  );
}

export default Multifield;
