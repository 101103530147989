import React, { useContext, useState } from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/authContext.js";
import http, { newuserUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(newuserUrlEndpoint + id);
    mutate();
    toast.success("Users Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function NewUserList(props) {
  const { currentUser } = useContext(AuthContext);
  let endPoint = newuserUrlEndpoint;
  const { isLoading, data: users, error } = useSWR(endPoint, fetcher);
  let num = 1;
 // console.log(users)
  //

  const [errors, setError] = useState(false);
  if (isLoading) return <p>{"Data is loading"}</p>;

  //Registration
  const registerHandler = async (e,user) => {
    setError(false);
    const {id,username, password, email, firm, role}= user;
    try {
     // console.log(user)
      //const res = await axios.post("/auth/register", {
      const res = await http.post(`auth/register`, { username,password,email,role, firm});
     // toast.success(res.data);
     toast.success("User Added successfully");
     console.log(res.data)
      const del= await http.delete(`newusers/`+ id) ;

    } catch (err) {
      setError(true);
    }
  };

  
  if (error) return <p>{error.message}</p>;

  
  return (
    <div>
      {/* The table */}

      {/* The search */}
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Users here"
          name="srch-term"
          id="srch-term"
          // onChange={changeHandler}
        />
      </form>

      <table class="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                # <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Firm <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Username <span class="caret"></span>
              </a>
            </td>
           

            <td>Email</td>
            <td>Role</td>
            <td> Register</td>
            <td>Delete</td>
          </tr>
        </thead>
        {users
          ? users.map((user, key) => {
              return (
                <tbody>
                  
                  <tr key={key}>
                    <td>{num++}</td>
                    <td>
                      
                        {user.firm}   
                                            
                    </td><td>
                      
                   {user.username}                     
                    
                  </td>
                  
                    <td>
                {user.email}
                </td>
                      <td>
                     {user.role}
                
                      </td>
                    
                    
                    <td>
                      <button
                        type="submit"
                        className="templatemo-link"
                        onClick={(e) =>registerHandler(e,user)}
                      >
                        Add User
                      </button>
                    </td>
                    <td>
                      <Link
                        to=""
                        onClick={() => handleDelete(user)}
                        class="templatemo-edit-btn"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </tbody>
              );
            })
          : isLoading && <p>Loading...</p>}
      </table>
      {/* Pagination */}
    </div>
  );
}

export default NewUserList;
