import React, { useState } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";

import { getmarketUrlEndpoint } from "../components/http.jsx";

import MarketTable from "../tables/marketTable.jsx";
import Pagination2 from "../components/pagination2.jsx";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function AllMarketList(props) {
  const [currentPage, setCurrentPage] = useState(1);

  let endPoint = getmarketUrlEndpoint;
  const { isLoading, data: market, error } = useSWR(endPoint, fetcher);
  if (isLoading) {
    return <div>Loading data...</div>;
  }

  if (error) return <p>{error.message}</p>;

  //Search

  //for pagination
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const orders = market.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);
  return (
    <div>
      {/* The table */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to="/write" class="blue-text">
              List the new market here
            </Link>
          </strong>
        </p>
      </div>
      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}

      {/* Pagination */}
      {market ? (
        <MarketTable market={orders} />
      ) : (
        isLoading && <p>Loading...</p>
      )}
      <Pagination2
        order={market.length}
        recordsPerPage={recordsPerPage}
        paginate={paginate}
      />
    </div>
  );
}

export default AllMarketList;
