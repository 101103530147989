import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";


export default axios.create({
   //baseURL: "http://localhost:7000/api/",  
    //withCredentials: true,


   baseURL: "https://cloud.247tranzact.com/api/",
  headers: {
    "Content-Type": "application/json",
    
  },
});
//export const url = "http://localhost:7000/api/";
export const url = "https://cloud.247tranzact.com/api/";
export const imgUrl = "https://cloud.247tranzact.com/images/";

export const getpostUrlEndpoint = "https://api.247tranzact.com/api/post/";
export const postUrlEndpoint = url + "post/";

export const productUrlEndpoint = url + "product/";
export const orderEndpoint = url + "orders/";
export const defaultUrlEndpoint = url + "payment/default";

export const paymentUrlEndpoint = url + "payment/";
export const saleUrlEndpoint = url + "sale/";
export const getmarketUrlEndpoint = "https://api.247tranzact.com/api/market/";

export const marketUrlEndpoint = url + "market/";
export const userUrlEndpoint = url + "users/";
export const newuserUrlEndpoint = url + "newusers/";

export const options = [
  {
    label: "Agriculture and Raw material",
    value: "Agriculture and Raw material",
  },
  { label: "Apparel and Accessories", value: "Apparel and Accessories" },
  { label: "Home and Garden", value: "Home and Garden" },
  { label: "Beauty & Personal Care", value: "Beauty & Personal Care" },
  { label: "Consumer Electronics", value: "Consumer Electronics" },
  { label: "Sports and Entertainment", value: "Sports and Entertainment" },
  { label: "Packaging and Printing", value: "Packaging and Printing" },
  { label: "Industrial Machines", value: "Industrial Machines" },
  { label: "Shoes and Accesories", value: "Shoes and Accesories" },
  {
    label: "Vehicle Parts and Accessories",
    value: "Vehicle Parts and Accessories",
  },
  { label: "Furniture", value: "Furniture" },
  { label: "Home Appliances", value: "Home Appliances" },
  { label: "Tools and Hardware", value: "Tools and Hardware" },
  { label: "Tech and Softwares", value: "Tech and Softwares" },

  { label: "Luggages and Bags", value: "Luggages and Bags" },
  { label: "Construction & Paints", value: "Construction & Paints" },
  { label: "Lights & Lighting", value: "Lights & Lighting" },
  {
    label: "Health Care and Medical Supplies",
    value: "Health Care and Medical Supplies",
  },
  {
    label: "School and Office Supplies",
    value: "School and Office Supplies",
  },
  { label: "Renewable Energy", value: "Renewable Energy" },
  { label: "Food and Beverage", value: "Food and Beverage" },
  { label: "Chemicals", value: "Chemicals" },
];
