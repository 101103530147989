import React from "react";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import PaymentList from "../list/PaymentList";

function Payment() {
  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />

          <PaymentList />
        </div>
      </div>
    </div>
  );
}

export default Payment;
