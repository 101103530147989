import React, { useContext, useState } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";

import { orderEndpoint } from "../components/http.jsx";
import { AuthContext } from "../context/authContext.js";
import OrderTable from "../tables/orderTable.jsx";
import Pagination2 from "../components/pagination2.jsx";
const fetcher = (...args) => fetch(...args).then((response) => response.json());

function OrderList(props) {
  const { currentUser } = useContext(AuthContext);
  let product = props.id;
  //console.log(product);
  let endPoint = orderEndpoint + currentUser.firm;

  const { isLoading, data: productOrder, error } = useSWR(endPoint, fetcher);
  //console.log(productOrder);

  const defaultHandler = (product) => {
    if (product) return productOrder.filter((f) => f.product === product);
  };
  const order = defaultHandler(product);
  // Search;
  const [produce, setProduct] = useState(order);

  const changeHandler = (e) => {
    setProduct(
      order.filter((f) => f.description.toLowerCase().includes(e.target.value))
    );
  };
  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };*/

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 1;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const orders = product && produce.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);
  //for pagination

  if (error) return <p>{error.message}</p>;

  return (
    <div className="templatemo-content-container">
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-flex-row flex-content-row">
          <div class="templatemo-content-widget white-bg col-2">
            <i class="fa fa-times"></i>
            <div class="media margin-bottom-30">
              <div class="media-body">
                <h2 class="media-heading text-uppercase blue-text">
                  {currentUser.username}
                </h2>
                <p>{currentUser.email}</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>Total Orders</td>
                    <td>{order && order.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* The table */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to={`/addBooking/`} class="blue-text">
              List the new Orders here
            </Link>
          </strong>
        </p>
      </div>
      {/* The search */}
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>
      {/* Pagination 
      <div>
        {order ? <list={order} /> : isLoading && <p>Loading...</p>}
      </div>{" "}
      <Pagination list={order} />*/}
      {order ? <OrderTable list={orders} /> : isLoading && <p>Loading...</p>}
      {order && (
        <Pagination2
          order={order.length}
          recordsPerPage={recordsPerPage}
          paginate={paginate}
        />
      )}
    </div>
  );
}

export default OrderList;
