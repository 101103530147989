import React from "react";
import { mutate } from "swr";

import { Link } from "react-router-dom";
import http, { productUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";
function ProductTable({ products }) {
  let num = 1;

  const handleDelete = async ({ id }) => {
    try {
      await http.delete(productUrlEndpoint + id);
      mutate();
      toast.success("Product Deleted Successfully");
    } catch (error) {
      toast.error(error.message, {
        duration: 60,
      });
    }
  };

  return (
    <table class="table table-striped table-bordered templatemo-user-table">
      <thead>
        <tr>
          <td>
            <a href="" class="white-text templatemo-sort-by">
              # <span class="caret"></span>
            </a>
          </td>
          <td>
            <a href="" class="white-text templatemo-sort-by">
              Title <span class="caret"></span>
            </a>
          </td>
          <td>
            <a href="" class="white-text templatemo-sort-by">
              Price <span class="caret"></span>
            </a>
          </td>
          <td>
            <a href="" class="white-text templatemo-sort-by">
              Category <span class="caret"></span>
            </a>
          </td>
          <td>
            <a href="" class="white-text templatemo-sort-by">
              Firm <span class="caret"></span>
            </a>
          </td>
          <td> Description</td>
          <td>Edit</td>
          <td>View</td>
          <td>Delete</td>
        </tr>
      </thead>
      {products &&
        products.map((product, key) => {
          return (
            <tbody>
              <tr key={key}>
                <td>{num++}</td>
                <td>
                  <Link
                    to={`/product/${product.slug}`}
                    state={product}
                    className="templatemo-link"
                  >
                    {product.title}
                  </Link>
                </td>
                <td>
                  {JSON.parse(product.price).map((price, key) => {
                    return (
                      <tr key={key}>
                        <td>{price.hallCapacity}:</td>
                        <td>{price.hallPrice}</td>
                      </tr>
                    );
                  })}
                </td>
                <td>{product.category}</td>
                <td>{product.firm}</td>
                <td>{product.description.slice(0, 200)}</td>
                <td>
                  <Link
                    to={`/addProduct/${product.slug}`}
                    state={product}
                    class="templatemo-edit-btn"
                  >
                    {" "}
                    Edit
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/product/${product.slug}`}
                    state={product}
                    className="templatemo-link"
                  >
                    View
                  </Link>
                </td>

                <td>
                  <Link
                    to=""
                    onClick={() => handleDelete(product)}
                    class="templatemo-edit-btn"
                  >
                    Delete
                  </Link>
                </td>
              </tr>
            </tbody>
          );
        })}
    </table>
  );
}

export default ProductTable;
