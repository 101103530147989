import React, { useContext } from "react";
import { AuthContext } from "../context/authContext";
import useSWR from "swr";
import {
  orderEndpoint,
  productUrlEndpoint,
  paymentUrlEndpoint,
  userUrlEndpoint,
} from "./http";
function Details(props) {
  const fetcher = (...args) =>
    fetch(...args).then((response) => response.json());
  const { currentUser } = useContext(AuthContext);
  const endPoint1 = productUrlEndpoint + currentUser.firm;
  const endPoint2 = paymentUrlEndpoint + currentUser.firm;
  const endPoint3 = userUrlEndpoint + currentUser.firm;
  const endPoint4 = orderEndpoint + currentUser.firm;

  const {
    isLoading: isptyLoading,
    data: property,
    error,
  } = useSWR(endPoint1, fetcher);
  const {
    isLoading: ispayLoading,
    data: payment,
    error: err,
  } = useSWR(endPoint2, fetcher);
  const {
    isLoading: isUserLoading,
    data: users,
    error: errs,
  } = useSWR(endPoint3, fetcher);

  const {
    isLoading: isOrderLoading,
    data: events,
    error: errevs,
  } = useSWR(endPoint4, fetcher);
  if ((isOrderLoading, isUserLoading, ispayLoading, isptyLoading))
    return <p>{"Data is Loading"}</p>;

  if ((error, err, errs, errevs)) return <p>{"Error in Connection"}</p>;
  //console.log(property);
  //console.log(payment);
  //console.log(users);
  let summary1 = property && property.length;
  //console.log(summary1, currentUser.firm);
  let summary2 = payment && payment.length;
  let summary3 = events && events.length;
  let summary4 = users && users.length;

  return (
    <div class="templatemo-flex-row flex-content-row">
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-content-widget white-bg col-2">
          <i class="fa fa-times"></i>
          <div class="media margin-bottom-30">
            <div class="media-left padding-right-25">
              <a href="#">
                <img
                  class="media-object img-circle templatemo-img-bordered"
                  src="assets/images/person.jpg"
                  alt="Sunset"
                />
              </a>
            </div>
            <div class="media-body">
              <h2 class="media-heading text-uppercase blue-text">
                {currentUser.username}
              </h2>
              <p>{currentUser.email}</p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <div class="circle green-bg"></div>
                  </td>
                  <td>Total Products</td>
                  <td>{summary1}</td>
                </tr>
                <tr>
                  <td>
                    <div class="circle pink-bg"></div>
                  </td>
                  <td>Total No of Payment</td>
                  <td>{summary2}</td>
                </tr>
                <tr>
                  <td>
                    <div class="circle blue-bg"></div>
                  </td>
                  <td>Total Number of Orders</td>
                  <td>{summary3}</td>
                </tr>
                <tr>
                  <td>
                    <div class="circle yellow-bg"></div>
                  </td>
                  <td>Total Users</td>
                  <td>{summary4}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
          <img
            src="assets/images/sunset-big.jpg"
            alt="Sunset"
            class="img-responsive content-bg-img"
          />
          <i class="fa fa-heart"></i>
          <h2 class="templatemo-position-relative white-text">Sunset</h2>
          <div class="view-img-btn-wrap">
            <a href="" class="btn btn-default templatemo-view-img-btn">
              View
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
