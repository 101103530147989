import React, { useContext, useState } from "react";
import useSWR from "swr";
import Pagination2 from "../components/pagination2.jsx";
import { saleUrlEndpoint } from "../components/http.jsx";
import { AuthContext } from "../context/authContext.js";
import SalesTable from "../tables/salesTable.jsx";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function PropertyPayment(props) {
  const { currentUser } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);

  let product = props.id;
  console.log(product);
  let endPoint = saleUrlEndpoint + currentUser.firm;

  const { isLoading, data: productPayment, error } = useSWR(endPoint, fetcher);
  if (isLoading) return <p>{"Data is Loading"}</p>;
  console.log(productPayment);
  const defaultHandler = (product) => {
    return productPayment.filter((f) => f.product == product);
  };

  const payment = defaultHandler(product);

  //for pagination
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const payments = payment.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);

  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };
  const filter = payment.filter((f) => f.propertyId.includes(props.id));
  console.log(filter);*/

  if (error) return <p>{error.message}</p>;

  return (
    <div className="templatemo-content-container">
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-flex-row flex-content-row">
          <div class="templatemo-content-widget white-bg col-2">
            <i class="fa fa-times"></i>
            <div class="media margin-bottom-30">
              <div class="media-body">
                <h2 class="media-heading text-uppercase blue-text">
                  {currentUser.username}
                </h2>
                <p>{currentUser.email}</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>Total Payments</td>
                    <td>{payment && payment.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* The table */}

      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}
      {payment ? (
        <SalesTable payments={payments} />
      ) : (
        isLoading && <p>Loading...</p>
      )}
      {/* Pagination */}
      <Pagination2
        order={payment.length}
        recordsPerPage={recordsPerPage}
        paginate={paginate}
      />
    </div>
  );
}

export default PropertyPayment;
