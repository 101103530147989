import Footer from "../components/footer";
import { React, useState, useContext, createContext } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { useLocation } from "react-router-dom";


//export const recoveryContext = createContext()

function ResetPassword(props) {
  const state = useLocation().state;
  const {email, otp} = state

 // const { login, page, setPage, email, setEmail, setOTP } = useContext(AuthContext);

//Toggle Password
//const [isOpen, setIsOpen] = useState(false);
console.log( otp)
  const [userOtp, otpUser] = useState("");
  const [error, setError] = useState(false);
  //Login
  const loginHandler = async (e) => {
    e.preventDefault();
    if(otp ===userOtp)    try {
            window.location.replace("/confirm-password");
    } catch (err) {
      toast.error("Incorrect OTP")
      setError(true);    }
  };

  return (
    <>
      <div className="templatemo-content col-1 light-gray-bg">
        <div className="templatemo-content-widget templatemo-login-widget white-bg">
          <header className="text-center">
            <div className="square"></div>
            <h1>Excellent Shop Managers</h1>
            <hr/>
            <p>Enter OTP sent to your email, {email}. Expires in 5 Minutes</p>
          </header>
          {error && (
            <span style={{ color: "red" }}> Login is unsuccessful</span>
          )}

          <form
            method="post"
            onSubmit={loginHandler}
            className="templatemo-login-form"
          >
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="fa fa-user fa-fw"></i>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter OTP"
                  required={true}
                  onChange={(e) => otpUser(e.target.value)}
                />
              </div>
            </div>
        
            
            <div className="form-group">
              <button
                type="submit"
                className="templatemo-blue-button width-100"
              >
                Enter OTP
              </button>
            </div>
          </form>
        </div>
        <div className="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <p>
            Not a registered user yet?{" "}
            <strong>
              <a href="https://home.rentandflex.com/sign-up" className="blue-text">
                Sign up now!
              </a>
            </strong>
          </p>
        </div>
    
        <Footer />
      </div>
    </>
  );
}

export default ResetPassword;
