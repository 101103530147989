import React from "react";
import { Link } from "react-router-dom";
import http, { paymentUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";
function SalesTable({ payments }) {
  let num = 1;
  const handleDelete = async ({ id }) => {
    try {
      await http.delete(paymentUrlEndpoint + id);
      toast.success("Post Added Successfully");
    } catch (error) {
      toast.error(error.message, {
        duration: 60,
      });
    }
  };

  return (
    <div class="templatemo-content-widget no-padding">
      <div class="panel panel-default table-responsive">
        <table class="table table-striped table-bordered templatemo-user-table">
          <thead>
            <tr>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  # <span class="caret"></span>
                </a>
              </td>
              <td>Date</td>

              <td>
                <a href="" class="white-text templatemo-sort-by">
                  Customer <span class="caret"></span>
                </a>
              </td>

              <td>Quantity</td>
              <td>Payment</td>

              <td>Delete</td>
            </tr>
          </thead>
          {payments &&
            payments.map((payment, key) => {
              return (
                <tbody>
                  <tr key={key}>
                    <td>{num++}</td>

                    <td>{payment.recordDate}</td>
                    <td>{payment.customer}</td>

                    <td>{payment.quantity}</td>
                    <td>{payment.sum}</td>

                    <td>
                      <Link
                        to=""
                        onClick={() => handleDelete(payment)}
                        class="templatemo-edit-btn"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </tbody>
              );
            })}
        </table>
      </div>
    </div>
  );
}

export default SalesTable;
