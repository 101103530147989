import React, { useContext, useState } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/authContext.js";
import { paymentUrlEndpoint } from "../components/http.jsx";
import PaymentTable from "../tables/paymentTable.jsx";
import Pagination2 from "../components/pagination2.jsx";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function PaymentList(props) {
  const [currentPage, setCurrentPage] = useState(1);

  const { currentUser } = useContext(AuthContext);
  let endPoint = paymentUrlEndpoint + currentUser.firm;

  const { isLoading, data: payment, error } = useSWR(endPoint, fetcher);

  if (isLoading) {
    return <div>Loading data...</div>;
  }

  //for pagination
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const payments = payment && payment.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);
  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };
  //const filter = payment.filter((f) => f.propertyId.includes(props.id));
  //console.log(filter);
  const currentDate = new Date().toLocaleDateString();

  const defaultHandler = (currentdate) => {
    return payment.filter((f) => f.endDate >= currentdate);
  };

  const expiredRents = defaultHandler(currentDate);
  console.log(expiredRents);*/

  if (error) return <p>{error.message}</p>;

  return (
    <div className="templatemo-content-container">
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-flex-row flex-content-row">
          <div class="templatemo-content-widget white-bg col-2">
            <i class="fa fa-times"></i>
            <div class="media margin-bottom-30">
              <div class="media-left padding-right-25">
                <a href="#">
                  <img
                    class="media-object img-circle templatemo-img-bordered"
                    src="assets/images/person.jpg"
                    alt="Sunset"
                  />
                </a>
              </div>
              <div class="media-body">
                <h2 class="media-heading text-uppercase blue-text">
                  {currentUser.username}
                </h2>
                <p>{currentUser.email}</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>Total Payments</td>
                    <td>{payment && payment.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* The table */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to="/input" class="blue-text">
              List the new Sales here
            </Link>
          </strong>
        </p>
      </div>
      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}
      {payment ? (
        <PaymentTable payments={payments} />
      ) : (
        isLoading && <p>Loading...</p>
      )}
      <Pagination2
        order={payment.length}
        recordsPerPage={recordsPerPage}
        paginate={paginate}
      />
      {/* Pagination */}

      <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
        <img
          src="assets/images/sunset-big.jpg"
          alt="Sunset"
          class="img-responsive content-bg-img"
        />
        <i class="fa fa-heart"></i>
        <h2 class="templatemo-position-relative white-text">Sunset</h2>
        <div class="view-img-btn-wrap">
          <a href="" class="btn btn-default templatemo-view-img-btn">
            View
          </a>
        </div>
      </div>
    </div>
  );
}

export default PaymentList;
