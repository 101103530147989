import { useContext, useState } from "react";
import moment from "moment";
import React from "react";
import { AuthContext } from "../context/authContext";
import useSWR from "swr";
import http, { productUrlEndpoint } from "../components/http";
import toast from "react-hot-toast";
import Nav from "../components/nav";
const fetcher = (...args) => fetch(...args).then((response) => response.json());

function Inventory(props) {
  const { currentUser } = useContext(AuthContext);
  const [product, setProduct] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [user, setUsers] = useState([]);
  const [sum, setSum] = useState();
  const [errors, setErrors] = useState(false);
  const [customer, setName] = useState("");

  function calculation() {
    user.push({ product, quantity, price, sum });
    const total = user.reduce((total, user) => {
      total += Number(user.sum);
      return total;
    }, 0);
    // you want this
    setTotal(total);
    // Clear the input fields
    setProduct("");
    setQuantity("");
    setPrice("");
    setSum("");
  }
  const handlePrice = (e) => {
    const newPrice = parseFloat(e.target.value);
    if (!isNaN(newPrice)) {
      setPrice(newPrice);
      calculateTotal(newPrice, quantity);
    }
  };
  const handleQuantity = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (!isNaN(newQuantity)) {
      setQuantity(newQuantity);
      calculateTotal(price, newQuantity);
    }
  };
  const calculateTotal = (quantity, price) => {
    const newTotal = quantity * price;
    setSum(newTotal);
  };

  const handleDelete = (i) => {
    const deleteVal = [...user];
    deleteVal.splice(i, 1);
    const { sum } = deleteVal;
    setUsers(deleteVal);
    setTotal(sum);
  };

  let endPoint = productUrlEndpoint + currentUser.firm;
  const { isLoading, data: products, error } = useSWR(endPoint, fetcher);

  if (isLoading) {
    return <div>Loading data...</div>;
  }
  if (error) return <p>{error.message}</p>;
  let today = moment().format("DD MMMM YYYY hh:mm:ss a");
  let recordDate = today;
  let order = JSON.stringify(user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    user.forEach((orderItem) => {
      const { product, price, quantity, sum } = orderItem;
      const sales = {
        customer,
        product,
        price,
        quantity,
        sum,
        firm: currentUser.firm,
        username: currentUser.username,
        recordDate,
      };
      try {
        const res = http.post(`sale`, sales);
        toast.success(res.data);
        window.location.reload();
      } catch (err) {
        console.log(err.message);
        setErrors(true);
        toast.error(err.message);
      }
    });
    const data = {
      customer,
      order,
      total,
      firm: currentUser.firm,
      username: currentUser.username,
      recordDate,
    };
    try {
      const res = await http.post(`payment`, data);
      toast.success(res.data);
      window.location.reload();
    } catch (err) {
      console.log(err.message);
      setErrors(true);
      toast.error(err.message);
    }
  };
  return (
    <div class="templatemo-content col-1 light-gray-bg">
      <Nav />
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-flex-row flex-content-row">
          <div class="templatemo-content-widget white-bg col-2">
            <i class="fa fa-times"></i>
            <div class="media margin-bottom-30">
              <div class="media-left padding-right-25">
                <a href="#">
                  <img
                    class="media-object img-circle templatemo-img-bordered"
                    src="assets/images/person.jpg"
                    alt="Sunset"
                  />
                </a>
              </div>
              <div class="media-body">
                <h2 class="media-heading text-uppercase blue-text">
                  Inventory Management
                </h2>
                <p>{currentUser.firm}</p>
              </div>
            </div>
            <div class="table-responsive">
              {errors && (
                <span style={{ color: "red" }}> Post is unsuccessful</span>
              )}
              <table class="table">
                <thead>
                  <tr>
                    <td>
                      Customer Name <span class="caret"></span>
                    </td>
                    <td>
                      Select Product <span class="caret"></span>
                    </td>
                    <td>
                      Quantity <span class="caret"></span>
                    </td>
                    <td>
                      Price <span class="caret"></span>
                    </td>
                    <td>
                      Amount <span class="caret"></span>
                    </td>
                    <td>
                      Action <span class="caret"></span>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-addon"></div>
                          <input
                            name="Name"
                            type="text"
                            class="form-control"
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <div>
                          <span>Select Product Name</span>
                        </div>

                        <select
                          onChange={(e) => setProduct(e.target.value)}
                          class="form-control"
                          name="product"
                        >
                          {products
                            ? products.map((option) => (
                                <option key={option.id} value={option.title}>
                                  {option.title}
                                </option>
                              ))
                            : isLoading && <p>Products Name are Loading...</p>}
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          name="quantity"
                          value={quantity}
                          placeholder="Number of Order"
                          required={true}
                          onChange={handleQuantity}
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="number"
                          name="price"
                          value={price}
                          placeholder="Price"
                          autocomplete="on"
                          required
                          onChange={handlePrice}
                        />
                      </div>
                    </td>
                    <td>
                      <td>
                        <div class="form-group">
                          <input
                            type="number"
                            name="total"
                            value={sum}
                            placeholder="Total"
                            disabled
                          />
                        </div>
                      </td>{" "}
                    </td>
                    <td>
                      <button onClick={calculation}>Add</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  {user ? (
                    user.map((order, i) => {
                      return (
                        <tr key={i}>
                          {" "}
                          <td>{order.product}</td>
                          <td>{order.quantity}</td>
                          <td>{order.price}</td>
                          <td>{order.sum}</td>
                          <td>
                            <button onClick={() => handleDelete(i)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <span>Enter Orders</span>
                  )}
                </tbody>
              </table>
            </div>
            <h2>Total: N{total}</h2>

            <button onClick={(e) => handleSubmit(e)}>Complete</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
