import React, { useState, useContext } from "react";
import Nav from "../components/nav";
import http, { options } from "../components/http";
import { AuthContext } from "../context/authContext";
import { useLocation } from "react-router-dom";
import Multifield from "../components/multiField";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AddProduct() {
  const post = useLocation().state;
  const id = post && post.id;
  const [title, titleRef] = useState(post?.title || "");
  const { currentUser } = useContext(AuthContext);
  const myString = title;
  const slug = myString.replace(/\s+/g, "-").toLowerCase();
  //console.log(slug);

  const [category, setCategory] = useState(post?.category || "");

  const [description, landmarkRef] = useState(post?.description || "");
  const [order, hallRef] = useState(post?.order || "");
  const [error, setError] = useState(false);
  //Price Lists
  //Get Multifield
  const [price, rentRef] = useState(post?.price || "");
  const getBody = (price) => {
    rentRef(price);
  };
  console.log(price);
  //picture states
  const [file, selectedFile] = useState([]);

  var fileSelectedHandler = (event) => {
    selectedFile(event.target.files[0]);
  };

  //Get Quill
  //React Quill
  const [body, setValue] = useState(post?.body || "");

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "formula"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const module = { toolbar: toolbarOptions };

  //Date state
  let today = moment();
  let createdAt = post?.createdAt || today._d;

  //Upload Images
  const upload = async () => {
    try {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      const attachPics = await http.post(`upload`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return attachPics.data;
    } catch (error) {
      setError(true);
      console.log(error.response);
    }
  };
  //submit handler
  const writeHandler = async (e) => {
    e.preventDefault();
    const img = await upload();
    setError(false);

    const postItems = {
      username: currentUser.username,
      firm: currentUser.firm,
      title,
      slug,
      category,

      price,

      description,
      order,
      body,
      photo: file ? img : "",
      createdAt,
    };
    console.log(postItems);

    try {
      post
        ? await http.put(`/product/${id}`, {
            ...post,
            username: currentUser.username,
            firm: currentUser.firm,
            title,
            slug,
            price,
            category,

            description,
            photo: file ? img : "",
            order,
            body,
            createdAt,
          })
        : await http.post(`product`, postItems);
      window.location.replace("/profile");
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>247 Tranzact</h1>
            <p>For Excellent Wholesalers of Brands.</p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="name"
                name="title"
                placeholder="Enter Name of the Product"
                value={title}
                required="true"
                onChange={(e) => titleRef(e.target.value)}
              />
            </div>
            <div class="form-group">
              <div className="">
                <div>
                  <span>Select Category</span>
                </div>

                <select
                  onChange={(e) => setCategory(e.target.value)}
                  class="form-control"
                >
                  {options &&
                    options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div class="form-group">
              <textarea
                type="textarea"
                class="form-control"
                name="Landmark"
                id="landmark"
                placeholder="Describe your product"
                value={description}
                required
                onChange={(e) => landmarkRef(e.target.value)}
              />
            </div>

            <div class="form-group">
              <div>
                <span>Enter Prices</span>
              </div>
              <Multifield setField={getBody} />
            </div>

            <ReactQuill
              theme="snow"
              onChange={setValue}
              value={body}
              modules={module}
              placeholder="Enter Full specifications of the Product"
            />
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                name="minimum"
                id="minimum"
                value={order}
                placeholder="Enter Minimum Orders"
                required
                onChange={(e) => hallRef(e.target.value)}
              />
            </div>
            <div class="form-group">
              <input
                class="form-control"
                type="file"
                className="subscription-input-form"
                name="file"
                onChange={fileSelectedHandler}
              />
            </div>
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
