import React, { useContext } from "react";
import Nav from "../components/nav";
import { Link } from "react-router-dom";
import Sidebar from "../components/sideBar";
import parse from "html-react-parser";
import { AuthContext } from "../context/authContext";
import { marketUrlEndpoint } from "../components/http";
import useSWR from "swr";
function Market() {
  const { currentUser } = useContext(AuthContext);
  let admin = currentUser.role == 2;

  const fetcher = (...args) =>
    fetch(...args).then((response) => response.json());
  const endPoint = marketUrlEndpoint + currentUser.firm;

  const { isLoading, data: market, error } = useSWR(endPoint, fetcher);
  if (isLoading) return <p>{"Data failed to fetch"}</p>;


  let num = 1;

  /* Search input
  const [inputs, setInputs] = useState([]);
  //

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = posts.slice(firstIndex, lastIndex);
  const npage = Math.ceil(posts.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  // console.log(records);
  useEffect(() => {
    const fetchPosts = async () => {
      //  const res = await axios.get("/posts");
      const res = await http.get(`market`);
      setInputs(res.data);
      setPosts(res.data);
    };
    fetchPosts();
  }, []);
  console.log(records);
  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.body.toLowerCase().includes(e.target.value))
    );
  };

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changePage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
*/
  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          {  error &&  <p>{"Data failed to fetch. check your network Connection"}</p>
        }
          <div class="templatemo-content-container">
            <div class="templatemo-flex-row flex-content-row">
              <div class="templatemo-flex-row flex-content-row">
                <div class="templatemo-content-widget white-bg col-2">
                  <i class="fa fa-times"></i>
                  <div class="media margin-bottom-30">
                    <div class="media-left padding-right-25">
                      <a href="#">
                        <img
                          class="media-object img-circle templatemo-img-bordered"
                          src="assets/images/person.jpg"
                          alt="Sunset"
                        />
                      </a>
                    </div>
                    <div class="media-body">
                      <h2 class="media-heading text-uppercase blue-text">
                        {currentUser.username}
                      </h2>
                      <p>{currentUser.email}</p>
                    </div>
                  </div>
                </div>

                <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
                  <img
                    src="assets/images/sunset-big.jpg"
                    alt="Sunset"
                    class="img-responsive content-bg-img"
                  />
                  <i class="fa fa-heart"></i>
                  <h2 class="templatemo-position-relative white-text">
                    Sunset
                  </h2>
                  <div class="view-img-btn-wrap">
                    <a href="" class="btn btn-default templatemo-view-img-btn">
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* The table */}
            {/* The search 
            <form class="templatemo-search-form" role="search">
              <button type="submit" class="fa fa-search"></button>
              <input
                type="text"
                class="form-control"
                placeholder="Search Market here"
                name="srch-term"
                id="srch-term"
                onChange={changeHandler}
              />
            </form>*/}
            <div class="templatemo-content-widget no-padding">
              <div class="panel panel-default table-responsive">
                <table class="table table-striped table-bordered templatemo-user-table">
                  <thead>
                    <tr>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          # <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Business Name <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Address <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          City <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Description <span class="caret"></span>
                        </a>
                      </td>
                      {admin && <td>Edit</td>}
                      <td>View</td>
                    </tr>
                  </thead>
                  {market ? (
                    <tbody>
                      <tr>
                        <td>{num++}</td>
                        <td>{market.title}</td>
                        <td>{market.address}</td>
                        <td>{market.city}</td>
                        <td>{parse(market.body.slice(0, 200))}...</td>
                        {admin && (
                          <td>
                            <Link
                              to={`/write/`}
                              state={market}
                              class="templatemo-edit-btn"
                            >
                              {" "}
                              Edit
                            </Link>
                          </td>
                        )}
                        <td>
                          <Link
                            to={`/profile/${market.slug}`}
                            state={market}
                            className="templatemo-link"
                          >
                            View
                          </Link>
                        </td>

                        {admin && (
                          <td>
                            <a href="" class="templatemo-link">
                              Delete
                            </a>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  ) : (
                    isLoading && <p>Loading...</p>
                  )}
                </table>
                {/* Pagination 
                <div className="col-lg-12 col-12">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center mb-0">
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Previous"
                          onClick={prePage}
                        >
                          <span aria-hidden="true">Prev</span>
                        </Link>
                      </li>
                      {numbers.map((n, i) => {
                        <li
                          className="page-item active"
                          aria-current="page"
                          key={i}
                        >
                          console.log(n)
                          <Link
                            className="page-link"
                            to="/market"
                            onClick={changePage}
                          >
                            <span aria-hidden="true">{n}</span>
                          </Link>
                        </li>;
                      })}

                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Next"
                          onClick={nextPage}
                        >
                          <span aria-hidden="true">Next</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Market;
