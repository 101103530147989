import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useContext } from "react";

function Nav() {
  const { currentUser } = useContext(AuthContext);
  let oga = currentUser.role === 3;
  let admin = currentUser.role === 2;
  return (
    <div className="templatemo-top-nav-container">
      <div className="row">
        <nav className="templatemo-top-nav col-lg-12 col-md-12">
          <ul className="text-uppercase">
            <li>
              <Link to="/" className="active">
                Dashboard
              </Link>
            </li>
            <li>
              <Link to="/addProduct">Add Product</Link>
            </li>
            {oga && (
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            )}
            {oga ? (
              <li>
                <Link to="/registration">Sign up form</Link>
              </li>
            ) : (
              admin && (
                <li>
                  <Link to="/register">Sign up form</Link>
                </li>
              )
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Nav;
