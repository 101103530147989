import React, { useContext, useState } from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/authContext.js";
import { productUrlEndpoint } from "../components/http.jsx";
import ProductTable from "../tables/productTable.jsx";
import Pagination2 from "../components/pagination2.jsx";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function ProductList(props) {
  const [currentPage, setCurrentPage] = useState(1);

  const { currentUser } = useContext(AuthContext);
  let endPoint = productUrlEndpoint + currentUser.firm;
 // console.log(endPoint);
  const { isLoading, data: products, error } = useSWR(endPoint, fetcher);

  if (isLoading) {
    return <div>Loading data...</div>;
  }
  {
    /*//For search
  const [product, setProduct] = useState(products);

  const changeHandler = (e) => {
    setProduct(
      products.filter((f) => f.title.toLowerCase().includes(e.target.value))
    );
  };*/
  }

  //For pagination
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const produce = products && products.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);

  if (error) return <p>{error.message}</p>;

  return (
    <div>
      {/* The table */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to="/addProduct" class="blue-text">
              List the new Product here
            </Link>
          </strong>
        </p>
      </div>
      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Product here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}

      {products ? (
        <ProductTable products={produce} />
      ) : (
        isLoading && <p>Loading...</p>
      )}
      <Pagination2
        order={products.length}
        recordsPerPage={recordsPerPage}
        paginate={paginate}
      />

      {/* Pagination */}
    </div>
  );
}

export default ProductList;
