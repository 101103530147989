import React  from "react";
import Nav from "../components/nav";

import Sidebar from "../components/sideBar";
import Details from "../components/details";
import ProductList from "../list/productList";

function Property() {

  
  {/*//for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.body.toLowerCase().includes(e.target.value))
    );
  };

  
*/}
  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
            <Details
              th1={"Total Properties"}
              th2={"Total Cities"}
              th3={"Total States"}
              th4={"Vacant Properties"}
              // total={posts.length}
            />

            {/* The table */}

            {/* The search 
            <form class="templatemo-search-form" role="search">
              <button type="submit" class="fa fa-search"></button>
              <input
                type="text"
                class="form-control"
                placeholder="Search Market here"
                name="srch-term"
                id="srch-term"
                onChange={changeHandler}
              />
            </form>*/}
            <div class="templatemo-content-widget no-padding">
              <div class="panel panel-default table-responsive">
                <ProductList />{" "}
                {/* Pagination 
                <div className="col-lg-12 col-12">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center mb-0">
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Previous"
                          onClick={prePage}
                        >
                          <span aria-hidden="true">Prev</span>
                        </Link>
                      </li>
                      {numbers.map((n, i) => {
                        <li
                          className="page-item active"
                          aria-current="page"
                          key={i}
                        >
                          console.log(n)
                          <Link
                            className="page-link"
                            to="/market"
                            onClick={changePage}
                          >
                            <span aria-hidden="true">{n}</span>
                          </Link>
                        </li>;
                      })}

                      <li className="page-item">
                        <Link
                          className="page-link"
                          to="/market"
                          aria-label="Next"
                          onClick={nextPage}
                        >
                          <span aria-hidden="true">Next</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Property;
